import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { jobState, subscriptionState } from 'state-domains/domain';
import { setSessionId } from 'src/utilities';
import React from 'react';

export interface WebsocketMessage {
    event: string;
    notification_id: string;
    user_id: string;
}

const MAX_RECONNECT_ATTEMPTS = 10;
const RECONNECT_INTERVALS = 5000;

export const WebSocketManager = () => {
    const dispatch = useDispatch();

    const {
        selectors: { account: accountSelector },
    } = subscriptionState;

    const {
        actions: { updateJob, completeJob },
    } = jobState;

    const eventMap: Record<string, ((data: WebsocketMessage) => void)[]> = React.useMemo(
        () => ({
            jobUpdated: [
                (data: WebsocketMessage) => {
                    updateJob(data.event)(dispatch);
                },
            ],
            jobCompleted: [
                (data: WebsocketMessage) => {
                    completeJob(data.event);
                },
            ],
        }),
        [],
    );

    const account = useSelector(accountSelector);

    const websocketUrl = React.useMemo(() => {
        if (account?.domain) {
            return `wss://${account.domain}/websocket`;
        }
        return null;
    }, [account]);

    useWebSocket(websocketUrl, {
        onOpen: setSessionId,
        shouldReconnect: () => true,
        reconnectAttempts: MAX_RECONNECT_ATTEMPTS,
        reconnectInterval: RECONNECT_INTERVALS,
        onMessage: (event: MessageEvent) => {
            try {
                const eventData = JSON.parse(event.data);

                if (eventData.notificationType in eventMap) {
                    eventMap[eventData.notificationType].forEach(
                        (callback: (data: any) => void) => {
                            callback(eventData.notificationData);
                        },
                    );
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Failed to parse WebSocket message:', error);
            }
        },
    });
    return <></>;
};
